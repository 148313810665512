@import '~@ob-npm-common/ob-styleguide/dist/ob-styleguide.min';
@import '~@ob-npm-common/ob-styleguide/dist/scss/variables';

#recaptcha {
  position: absolute;
}
.grecaptcha-badge {
  bottom: 70px !important;
}

.submit-btn {
  background-color: #f08421;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  outline: none;
  border: 0;
}
