body {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
}

.header-container {
  padding: 30px 45px 20px 45px;
  margin: 0 0 0 0;
  background: #ffffff;
}

.oops-container {
  align-self: center;
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 26px;

  .oops-header {
    margin-top: 20px;
    margin-bottom: 24px;
    width: 126px;
    height: 55px;
    font-family: "open-sans", sans-serif;
    font-size: 40px;
    letter-spacing: 1.4px;
    color: #222222;
  }

  .oops-instructions {
    width: 450px;
    height: 182px;
    font-family: "open-sans", sans-serif;
    font-size: 14px;
    line-height: 1.86;
    letter-spacing: 0.5px;
    color: #222222;
    a {
      color: #ef8321;
    }
    .oops-contact {
      margin-left: -26px;
      img {
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
}
