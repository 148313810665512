.header-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e4e7;
  align-items: center;
  align-content: center;
  padding: 0 18px;
  height: 48px;
  background: #fff;
}

.logo-container {
  img {
    height: 24px;
  }
}

.user-container {
  display: flex;
  align-self: center;
  padding-right: 2px;
  font-size: 13px;
  .user-name {
    padding-right: 7px;
    margin-right: 7px;
    border-right: 1px solid #ccc;
  }
}
