.ticket-error-container {
  .ticket-error-message {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 12px 40px;
    color: #be3655;
    background-color: #f9dbdd;
    border: 1px solid #ccc;
  }
}
